import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RenderJobSubmitModal from "./RenderJobSubmitModal";

const AddImagePresetsModal = ({
  setModalShow,
  modalShow,
  refetch,
  setRefetch,
}) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [host, setHostName] = useState("");
  const [company, setCompanyname] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [presetsModal, setPresetsModal] = useState(false);
  const fileInputRef = useRef();
  const [resolutionOptions, setResolution] = useState([]);
  const [fileType, setFileType] = useState("");
  const [outputFileTypeOptions, setOutputFileType] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const goHome = useNavigate();

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  const toastWarn = (mes) => {
    toast.warn(mes, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleAddedPreset = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name.value;
    const width = parseInt(target.width.value);
    const height = parseInt(target.height.value);
    if (width < 2 || height < 2) {
      toastWarn(`Width/Height not allowed less than 2 pixel`);
      return;
    }
    const data = { name, width, height };
    setResolution([...resolutionOptions, data]);
    setPresetsModal(false);
  };

  const removeJob = (job) => {
    const filterJob = resolutionOptions.filter((j) => j.name !== job);
    if (filterJob) {
      setResolution(filterJob);
    } else {
      setResolution([]);
    }
  };

  const validateSceneTemplate = (e) => {
    const { name } = e.target.files[0];
    if (!name.endsWith(".blend")) {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      toastWarn(`Only allowed .blend file`);
      return;
    }
  };

  const validateOutputFile = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      if (input.files[0].type != "image/jpeg") {
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        toastWarn(`Only allowed image/jpeg file`);
        return;
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          const maxWidth = 512;
          const maxHeight = 512;

          if (width > maxWidth && height > maxHeight) {
            toastWarn(
              `Please choose an image with dimensions up to ${maxWidth}x${maxHeight}.`
            );
            input.value = "";
          }
        };
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  const addOutputFileType = () => {
    if (fileType) {
      const checkExists = outputFileTypeOptions.includes(fileType);
      if (!checkExists) {
        setOutputFileType([...outputFileTypeOptions, fileType]);
        setFileType("");
      } else {
        toastWarn(`${fileType} already in the list`);
      }
    }
  };

  const removeType = (type) => {
    const filterType = outputFileTypeOptions.filter((f) => f !== type);
    setOutputFileType(filterType);
  };
  // console.log(outputFileType);

  const submitImagePreset = async (e) => {
    e.preventDefault();
    if (!company) {
      toastWarn(`Please select a company`);
      return;
    }
    if (!resolutionOptions.length > 0) {
      toastWarn(`Add at least one resolution`);
      return;
    }
    if (!outputFileTypeOptions.length > 0) {
      toastWarn(`Add at least one Output Filetype `);
      return;
    }
    setSubmitModal(true);
    const input = e.target;
    const clientCompanyId = input.clientCompanyId.value;
    const name = input.name.value;
    const description = input.description.value;
    const sceneTemplate = input.sceneTemplate.files[0];
    const thumbnail = input.thumbnail.files[0];
    const imagePreset = {
      name,
      description,
      resolutionOptions,
      outputFileTypeOptions,
    };
    let formData = new FormData();
    formData.append("clientCompanyId", clientCompanyId);
    formData.append("imagePreset", JSON.stringify(imagePreset));
    formData.append("sceneTemplate", sceneTemplate);
    thumbnail && formData.append("thumbnail", thumbnail);
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true,
    };
    await axios.post(`/imagePresetsRoute/new`, formData, config).then((res) => {
      if (res.status == 200) {
        setTimeout(() => {
          setSubmitModal(false);
          toast.success(`Successfully created new preset!`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          e.target.reset();
          setRefetch(!refetch);
          setModalShow(false);
          setCompanyname("");
          setResolution([]);
          setOutputFileType([]);
          goHome("/administration/imagePresets");
        }, 150);
      }
    });
    // console.log(formData);
  };

  const closeModalData = () => {
    setResolution([]);
    setOutputFileType([]);
    setCompanyname("");
    setModalShow();
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={closeModalData}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Image Preset
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitImagePreset}>
            <h6 className="group_title_presets fw-bold mb-3">Client</h6>
            <Form.Label>Company*</Form.Label>
            <Form.Select
              name="clientCompanyId"
              className="mb-3"
              value={company}
              onChange={(e) => setCompanyname(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.companyname}
                </option>
              ))}
            </Form.Select>

            <h6 className="group_title_presets fw-bold mb-3 mt-4">
              Image Preset
            </h6>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                name="name"
                required
                type="text"
                placeholder="Please add a preset name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description*</Form.Label>
              <Form.Control
                name="description"
                required
                type="text"
                placeholder="Please add a description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Resolution*</Form.Label>
              <br />
              <Button
                onClick={() => setPresetsModal(true)}
                className="mb-3 px-3"
                variant="primary"
                size="sm"
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </Button>
              {resolutionOptions && (
                <Accordion className="mb-2">
                  <div className="accroding_grid">
                    {resolutionOptions &&
                      resolutionOptions.map((r, index) => {
                        return (
                          <Col key={index}>
                            <Accordion.Item
                              className="single_acc_item presets_acc"
                              eventKey={index}
                            >
                              <Accordion.Header>{r?.name}</Accordion.Header>
                              <Accordion.Body>
                                <b>Width</b>: {r?.width},&nbsp;<b>Height:</b>{" "}
                                {r?.height} <br />
                                <Button
                                  onClick={() => removeJob(r?.name)}
                                  className="mt-3"
                                  variant="danger"
                                  size="sm"
                                >
                                  Remove
                                </Button>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                        );
                      })}
                  </div>
                </Accordion>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ocFile">
              <Form.Label>Scene Template*</Form.Label>
              <Form.Control
                required
                name="sceneTemplate"
                type="file"
                placeholder="Select File"
                onChange={validateSceneTemplate}
                accept=".blend"
                ref={fileInputRef}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ocFile">
              <Form.Label className="mt-2">Thumbnail</Form.Label>
              <Form.Control
                name="thumbnail"
                type="file"
                placeholder="Select File"
                onChange={validateOutputFile}
                accept="image/jpeg"
                ref={fileInputRef}
              />
            </Form.Group>
            <Form.Label className="mt-2">
              Output Filetype &nbsp;&nbsp;
            </Form.Label>
            {outputFileTypeOptions &&
              outputFileTypeOptions.map((t) => (
                <span
                  title="Click to remove"
                  onClick={() => removeType(t)}
                  className="singleType"
                  key={t}
                >
                  {t}
                </span>
              ))}
            <Row className="addtypearea">
              <Col>
                <Form.Group className="mb-3" controlId="exampleForm.ocFile">
                  <Form.Control
                    name=""
                    type="text"
                    value={fileType}
                    placeholder="Enter a filetype option"
                    onChange={(e) => setFileType(e.target.value)}
                    className="mt-3"
                  />
                </Form.Group>
              </Col>
              <Col>
                <span className="addOFT" onClick={addOutputFileType}>
                  Add
                </span>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Add Image Preset
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/*Presets Modal */}
      <Modal
        backdrop="static"
        onHide={() => setPresetsModal(false)}
        show={presetsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Resolution
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddedPreset}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                name="name"
                required
                type="text"
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Width*</Form.Label>
              <Form.Control
                name="width"
                required
                type="number"
                placeholder="width"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Height*</Form.Label>
              <Form.Control
                name="height"
                required
                type="number"
                placeholder="height"
              />
            </Form.Group>
            <Button type="submit" variant="primary" size="md">
              Add Resolution
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {submitModal && (
        <RenderJobSubmitModal
          title="Submitting Image Preset"
          showModal={submitModal}
        />
      )}
    </>
  );
};

export default AddImagePresetsModal;
