import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditCompanyModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const { editCompany } = useSelector((state) => state.company);
  const [companyname, setName] = useState("");
  const [billingemail, setEmail] = useState("");

  useEffect(() => {
    if (editCompany._id) {
      setName(editCompany.companyname);
      setEmail(editCompany.billingemail);
    }
  }, [editCompany]);

  const HandleEditCompany = async (e) => {
    e.preventDefault();
    const companyInfo = { companyname, billingemail };
    try {
      await axios
        .put(`/companyroute/update/${editCompany._id}`, companyInfo, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(`Company update successfully`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setName("");
            setEmail("");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("Email already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleEditCompany}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="text"
              value={companyname}
              className="email_filed"
              placeholder="Company name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="email"
              value={billingemail}
              placeholder="Company email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update company
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCompanyModal;
