import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editCompany: {},
  editapi: {},
  editPreset: {},
  editUser: {},
};

const editSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companyEdit: (state, action) => {
      state.editCompany = action.payload;
    },
    apiEdit: (state, action) => {
      state.editapi = action.payload;
    },
    presetEdit: (state, action) => {
      state.editPreset = action.payload;
    },
    presetRemove: (state) => {
      state.editPreset = {};
    },
    userEdit: (state, action) => {
      state.editUser = action.payload;
    },
  },
});

export const { companyEdit, apiEdit, userEdit, presetEdit, presetRemove } =
  editSlice.actions;
export default editSlice.reducer;
