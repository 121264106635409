import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  InputGroup,
  OverlayTrigger,
  Pagination,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import PageTitle from "../../hooks/PageTitle";
import { UseUserAdministrators } from "../../hooks/UseUserAdministrators";
import DeleteModal from "../modal/DeleteModal";
import "./Home.css";
import "./HomePageItemBar.css";

const HomePage = () => {
  const userRole = UseUserAdministrators();
  const { REACT_APP_REFRESH_JOB_LIST } = process.env;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(10);
  const [refetch, setRefetch] = useState(false);
  const [sort, setSort] = useState("");
  const [renderJob, setRenderJob] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    setIsLoading(true);
    if (!sort && !searchText) {
      axios
        .get(`/renderjobRoute/allscanjob?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          setRenderJob(res.data.RenderJob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else if (searchText == "" && sort != "") {
      axios
        .get(
          `/renderjobRoute/allscanjob?page=${page}&limit=${limit}&sort=${sort}`,
          { withCredentials: true }
        )
        .then((res) => {
          setRenderJob(res.data.RenderJob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else if (searchText && sort) {
      axios
        .get(
          `/renderjobRoute/search?search=${searchText}&page=${page}&limit=${limit}&sort=${sort}`,
          { withCredentials: true }
        )
        .then((res) => {
          // console.log(res);
          setRenderJob(res.data.RenderJob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else {
      axios
        .get(
          `/renderjobRoute/search?search=${searchText}&page=${page}&limit=${limit}`,
          { withCredentials: true }
        )
        .then((res) => {
          // console.log(res);
          setRenderJob(res.data.RenderJob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    }
  }, [page, limit, refetch, sort, searchText, refreshList]);

  let active = page;
  let items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      setRefreshList(!refreshList);
    }, parseInt(REACT_APP_REFRESH_JOB_LIST) * 60000 || 900000);
  }, [refreshList]);

  const paginationNumber = (e) => {
    const pageNumber = parseInt(e.target.innerText);
    setPage(pageNumber);
  };

  const sortingTable = (data) => {
    if (sort == data) {
      setSort("");
    } else {
      setSort(data);
    }
  };

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSearchText = (e) => {
    // console.log(e.target.value);
    setSearchText(e.target.value);
    setPage(1);
  };

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setPage(newPage);
      // console.log("eeeeeeeeeeeee");
      // console.log(page);
    }
  };

  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + 2, pageCount);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }

  const handleLimit = (e) => {
    setPage(1);
    setLimit(parseInt(e.target.value));
  };

  const delayCall = useCallback(debounce(handleSearchText), []);

  const deleteConfirm = () => {
    axios
      .delete(`/renderjobRoute/delete/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.msg) {
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const clearSearchText = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setSearchText("");
    setPage(1);
  };
  return (
    <div>
      <PageTitle title="Home" />
      <div className="containercustom">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <div className="home_page_item d-flex justify-content-between">
              <div className="home_page_item_left">
                <h3>Renderjob Overview</h3>
              </div>
              <div className="home_page_item_right d-flex">
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-bottom">
                      <Popover.Body className="body_prop_area">
                        Search by ID, ClientEmail, Company Name, Creator Name,
                        and RenderStatus.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </InputGroup.Text>
                    <Form.Control
                      onChange={delayCall}
                      placeholder="Enter search here"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      ref={inputRef}
                    />
                    <InputGroup.Text id="basic-addon1">
                      <i
                        onClick={clearSearchText}
                        title="Clear the search"
                        class="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                  </InputGroup>
                </OverlayTrigger>
              </div>
            </div>
            <div className="randerjob_area">
              <Table className="scanJob_table" bordered size="lg">
                <thead>
                  <tr>
                    <th
                      className={sort === "scannerId" ? "scannerId" : ""}
                      role="button"
                      onClick={() => sortingTable("scannerId")}
                    >
                      Id
                    </th>
                    <th
                      className={sort === "creatorId" ? "creatorId" : ""}
                      role="button"
                      onClick={() => sortingTable("creatorId")}
                    >
                      Creator</th>
                    <th
                      className={sort === "clientCompanyId" ? "clientCompanyId" : ""}
                      role="button"
                      onClick={() => sortingTable("clientCompanyId")}
                    >
                      ClientCompany</th>
                    <th>Resolution</th>
                    <th
                      className={sort === "outputFileType" ? "outputFileType" : ""}
                      role="button"
                      onClick={() => sortingTable("outputFileType")}
                    >
                      OutputFileType</th>
                    <th
                      className={sort === "outputColorProfile" ? "outputColorProfile" : ""}
                      role="button"
                      onClick={() => sortingTable("outputColorProfile")}
                    >
                      OutputColorProfile</th>
                    <th
                      className={sort === "clientEmail" ? "clientEmail" : ""}
                      role="button"
                      onClick={() => sortingTable("clientEmail")}
                    >
                      ClientEmail
                    </th>
                    <th>ClientComment</th>
                    <th>Camera Settings</th>
                    <th>Image Presets</th>
                    <th>3D Files</th>
                    <th
                      className={
                        sort === "renderStatus" ? "renderStatus" : ""
                      }
                      role="button"
                      onClick={() => sortingTable("renderStatus")}
                    >
                      RenderStatus
                    </th>
                    <th>Render Output</th>
                    <th
                      className={sort === "createdAt" ? "createdAt" : ""}
                      role="button"
                      onClick={() => sortingTable("createdAt")}
                    >
                      CreatedAt
                    </th>
                    <th
                      className={sort === "updatedAt" ? "updatedAt" : ""}
                      role="button"
                      onClick={() => sortingTable("updatedAt")}
                    >
                      UpdatedAt
                    </th>
                    {userRole ? <th>Actions</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {renderJob &&
                    renderJob.map((job) => {
                      const d = new Date(job.createdAt);
                      const p = new Date(job.updatedAt);
                      return (
                        <tr key={job._id}>
                          <td title={job._id}>{job._id}</td>
                          <td>
                            {job?.creator ? (
                              job.creator.username ? (
                                job.creator.username + " (User)"
                              ) : (
                                job.creator.hostName + " (Host)"
                              )
                            ) : (
                              <p style={{ textAlign: "center" }}>-</p>
                            )}
                          </td>
                          <td>
                            {job?.clientCompany ? (
                              job.clientCompany.companyname
                            ) : (
                              <p style={{ textAlign: "center" }}>-</p>
                            )}
                          </td>
                          <td>
                            W: {job?.resolutionHeight}
                            <br />
                            H: {job?.resolutionWidth}
                          </td>
                          <td>{job?.outputFileType}</td>
                          <td>{job?.outputColorProfile}</td>
                          <td>{job?.clientEmail}</td>
                          <td>
                            {job?.clientComment ? (
                              job.clientComment
                            ) : (
                              <p style={{ textAlign: "center" }}>-</p>
                            )}
                          </td>
                          <td>
                            fov: {job?.camera?.fov}, posX: {job?.camera?.posX},
                            posY: {job?.camera?.posY}
                            <br /> posZ: {job?.camera?.posZ}, targetX:{" "}
                            {job?.camera?.targetX}, targetY:{" "}
                            {job?.camera?.targetY}
                            <br /> targetZ: {job?.camera?.targetZ}, type:{" "}
                            {job?.camera?.type}
                          </td>
                          <td>
                            {job?.imagePresets?.length > 0 &&
                              job?.imagePresets.map((i, index) => {
                                return (
                                  <span>
                                    {index === job?.imagePresets?.length - 1
                                      ? `${i?.name} (${i?.outputFileType}, ${i?.resolution?.width}x${i?.resolution?.height})`
                                      : `${i?.name} (${i?.outputFileType}, ${i?.resolution?.width}x${i?.resolution?.height}),`}
                                    <br />
                                  </span>
                                );
                              })}
                          </td>
                          <td>
                            {job?.renderObjects?.map((file, index) => {
                              return (
                                <a
                                  key={index}
                                  href={`/assetsroute/download?filename=${file.ocFile}`}
                                  download={file.ocFile}
                                  title="Download"
                                  className="scanjob_btn dual_btn"
                                  size="sm"
                                  variant="secondary"
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              );
                            })}
                          </td>
                          <td>{job?.renderStatus}</td>
                          <td>
                            {job?.renderStatus == "completed" &&
                            job?.rendertasks.length > 0 ? (
                              job.rendertasks.map((task, index) => {
                                return (
                                  <a
                                    key={index}
                                    href={`/assetsroute/download?filename=${task.file}`}
                                    download={task.file}
                                    title="Download"
                                    className="scanjob_btn dual_btn"
                                    size="sm"
                                    variant="secondary"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                );
                              })
                            ) : (
                              <p style={{ textAlign: "center" }}>-</p>
                            )}
                          </td>
                          <td>
                            {d.getDate()}.{d.getMonth() + 1}.{d.getFullYear()}{" "}
                            {d.getHours()}:{d.getMinutes()}
                          </td>
                          <td>
                            {p.getDate()}.{p.getMonth() + 1}.{p.getFullYear()}{" "}
                            {p.getHours()}:{p.getMinutes()}
                          </td>

                          {userRole ? (
                            <td>
                              <span
                                role="button"
                                onClick={() => handleDelet(job._id, job._id)}
                                className="text-danger"
                              >
                                Delete
                              </span>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {isLoading && (
                <div className="scanJob_filter_loader">
                  <>
                    <Spinner size="lg" animation="border" />
                    &nbsp; <span>Loading RenderJobs...</span>
                  </>
                </div>
              )}
            </div>
            <div className="pagination_product">
              <Form.Select
                disabled={renderJob?.length < 5}
                onChange={handleLimit}
                className="limit_select-lg"
                value={limit}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
              <Pagination size="lg">
                <Pagination.First
                  onClick={() => handlePageClick(page - 1)}
                  disabled={page === 1}
                />
                {[...Array(endPage - startPage + 1)].map((_, index) => {
                  const pageNumber = startPage + index;
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === page}
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Last
                  onClick={() => handlePageClick(page + 1)}
                  disabled={page === pageCount}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />
    </div>
  );
};

export default HomePage;
