import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userLoggedIn } from "../../features/authSlice";
import PageTitle from "../../hooks/PageTitle";
import "./Login.css";

const Login = () => {
  const token = localStorage.getItem("accessToken");
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [user, setUser] = useState([]);
  const nagivateToHome = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const auth = JSON.parse(token);
    if (auth?.accessToken && auth?.user) {
      nagivateToHome("/");
    }
  }, [token]);

  useEffect(() => {
    if (user.success) {
      axios
        .get(`/assetsroute/profilePicture/${user?.user?._id}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.signedUrlResize) {
            setProfilePicture(res.data.signedUrlResize);
          } else if (res.data == "Not found") {
            setProfilePicture("404");
          }
        })
        .catch((error) => {
          setProfilePicture("404");
        });
    }
  }, [user]);

  useEffect(() => {
    if (user.success) {
      const timestamp = Date.now();
      dispatch(
        userLoggedIn({
          accessToken: user.token,
          profile: profilePicture,
          user: user.user,
          timestamp: timestamp,
        })
      );
      localStorage.setItem(
        "accessToken",
        JSON.stringify({
          accessToken: user.token,
          user: user.user,
          profile: profilePicture,
          timestamp: timestamp,
        })
      );
      nagivateToHome(from, { replace: true });
    }
  }, [profilePicture]);

  const HandleLogin = async (e) => {
    e.preventDefault();
    const loginInfo = { username: email, password };
    try {
      await axios
        .post(`/authroute/passport`, loginInfo, {
          withCredentials: true,
        })
        .then((res) => setUser(res.data));
    } catch (error) {
      if (error.response.status) {
        toast.error("User or password is incorrect", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <div className="login_form">
      <PageTitle title="Login" />
      <Form onSubmit={HandleLogin}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="mb-3">Sign in</Form.Label>
          <Form.Control
            required
            className="email_filed"
            type="text"
            value={email}
            placeholder="my@email.add"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            required
            type="password"
            value={password}
            placeholder="••••••••"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        {/* <Form.Group
          className="mb-3 d-flex justify-content-between"
          controlId="formBasicCheckbox"
        >
          <Form.Check type="checkbox" label="Check me out" />
          <Link to="">Forgot your password?</Link>
        </Form.Group> */}
        <Button className="lgn_btn" variant="primary" type="submit">
          Log in
        </Button>
      </Form>
    </div>
  );
};

export default Login;
