import { Navigate, useLocation } from "react-router-dom";
import { UseUserAdministrators } from "./UseUserAdministrators";

function CheckUserAdministrators({ children }) {
  let location = useLocation();
  const auth = UseUserAdministrators();
  if (!auth) {
    // localStorage.removeItem('accessToken')
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default CheckUserAdministrators;
