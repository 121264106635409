import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const AddCompanyModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [billingemail, setEmail] = useState("");
  const [companyname, setName] = useState("");

  const HandleAddCompany = async (e) => {
    e.preventDefault();
    const companyInfo = { companyname, billingemail };
    try {
      await axios
        .post(`/companyroute/new`, companyInfo, { withCredentials: true })
        .then((res) => {
          if (res.data._id) {
            toast.success(
              `Company "${res.data.companyname}" added successfully`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setName("");
            setEmail("");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("Email already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleAddCompany}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="text"
              value={companyname}
              className="email_filed"
              placeholder="Company name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="email"
              value={billingemail}
              placeholder="Company email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Company
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCompanyModal;
