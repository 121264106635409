import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const RenderJobSubmitModal = ({ showModal, title }) => {
  return (
    <Modal
      backdrop="static"
      // onHide={() => setModalShow(false)}
      show={showModal}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center pt-4">
        <Spinner animation="border" role="status"></Spinner>
        <p className="fw-bold mt-2 fs-7">{title}</p>
      </Modal.Body>
    </Modal>
  );
};

export default RenderJobSubmitModal;
