import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditApiModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const { editapi } = useSelector((state) => state.company);
  const [hostName, setHost] = useState("");
  const [code, setCountryCode] = useState("");
  const countryCode = code.toUpperCase();
  const [company, setCompanyname] = useState("");
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  useEffect(() => {
    if (editapi._id) {
      setCompanyname(editapi?.company?._id);
      setHost(editapi.hostName);
    }
  }, [editapi]);

  const HandleEditApi = async (e) => {
    e.preventDefault();
    const apiInfo = { company, hostName, api_key: editapi.api_key };
    try {
      await axios
        .put(`/renderjobRoute/api/update/${editapi._id}`, apiInfo, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(`Api update successfully`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setCountryCode("");
            setHost("");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("Api already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Api info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleEditApi}>
          <Form.Select
            className="mb-3"
            value={company}
            onChange={(e) => setCompanyname(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select Company</option>
            {companyList &&
              companyList.map((company, index) => (
                <option key={company._id} value={company._id}>
                  {company.companyname}
                </option>
              ))}
          </Form.Select>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="text"
              value={hostName}
              className="email_filed"
              placeholder="Host name"
              onChange={(e) => setHost(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Api
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditApiModal;
