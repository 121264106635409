import React from "react";
import { Outlet } from "react-router-dom";
import PageTitle from "../../hooks/PageTitle";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <section className="dashboard-area ptb-54">
      <PageTitle title="Administration" />
      <div className="containercustom">
        <div className="row">
          {/* <div className="col-lg-3">
						<ul className="dashboard-navigation">
							<li>
								<h3 className="active">Administration</h3>
							</li>

							<li>
								<CustomLink to='/administration'>User</CustomLink>
							</li>
							<li>
								<CustomLink to='/administration/company-new'>Company</CustomLink>
							</li>
							<li>
								<CustomLink to='/administration/country-new'>Country</CustomLink>
							</li>


						</ul>
					</div> */}

          <div className="col-lg-12">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
