import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { presetEdit } from "../../features/editSlice";
import PageTitle from "../../hooks/PageTitle";
import AddImagePresetsModal from "../modal/AddImagePresetsModal";
import DeleteModal from "../modal/DeleteModal";
import EditPresetModal from "../modal/EditPresetModal";

const ImagePresets = () => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [modalShow, setModalShow] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowEditPreset, setModalShowEditPreset] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(5);
  const [company, setCompanyname] = useState("404");
  const [companyList, setCompanyList] = useState([]);
  const [companyObj, setCompanyObj] = useState([]);
  const [imagePresets, setImagePresets] = useState([]);

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  useEffect(() => {
    let dataContary = {};
    companyList.map((c) => {
      dataContary = {
        ...dataContary,
        [c._id]: c.companyname,
      };
    });
    setCompanyObj(dataContary);
  }, [companyList]);

  useEffect(() => {
    if (company == "404") {
      axios
        .get(`/imagePresetsRoute/list?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          processImageData(res.data.imagePresets);
          setPageCounte(res.data.pageCount);
        });
    } else {
      axios
        .get(
          `/imagePresetsRoute/company/${company}?page=${page}&limit=${limit}`,
          { withCredentials: true }
        )
        .then((res) => {
          processImageData(res.data.imagePresets);
          setPageCounte(res.data.pageCount);
        });
    }

    // setCompanyname('404')
  }, [refetch, page, limit]);

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };
  const deleteConfirm = () => {
    axios
      .delete(`/imagePresetsRoute/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const handleEditPreset = (preset) => {
    setModalShowEditPreset(true);
    dispatch(presetEdit(preset));
  };

  let active = page;
  let items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const paginationNumber = (e) => {
    const pageNumber = parseInt(e.target.innerText);
    setPage(pageNumber);
  };
  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  const handleApiFilter = (id) => {
    setCompanyname(id);
    setPage(1);
    if (id == "404") {
      axios
        .get(`/imagePresetsRoute/list?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          processImageData(res.data.imagePresets);
          setPageCounte(res.data.pageCount);
        });
    } else {
      axios
        .get(`/imagePresetsRoute/company/${id}?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          processImageData(res.data.imagePresets);
          setPageCounte(res.data.pageCount);
        });
    }
  };

  const processImageData = async (dataArray) => {
    const fetchDataForImage = async (fileName) => {
      if (!fileName) {
        return null;
      }
      try {
        const response = await fetch(
          `/assetsroute/thumbnail?filename=${fileName}`
        );
        const data = await response.json();
        return data.signedUrl;
      } catch (error) {
        console.error(`Error fetching live URL for ${fileName}:`, error);
        return null;
      }
    };

    const updatedDataArray = await Promise.all(
      dataArray.map(async (item) => {
        const liveUrlForSceneTemplate = await fetchDataForImage(
          item.sceneTemplate
        );
        const liveUrlForThumbnail = await fetchDataForImage(item.thumbnail);

        return {
          ...item,
          thumbnailLiveUrl: liveUrlForThumbnail,
          sceneTemplateLiveUrl: liveUrlForSceneTemplate,
        };
      })
    );
    setImagePresets(updatedDataArray);
  };

  return (
    <div className="login_form dashboard_page">
      <PageTitle title="Image Presets" />

      <div className="filter_company">
        <Form.Select
          className="mb-3"
          value={company}
          onChange={(e) => handleApiFilter(e.target.value)}
          aria-label="Default select example"
        >
          <option value="404">All Company</option>
          {companyList.map((company) => (
            <option key={company._id} value={company._id}>
              {company.companyname}
            </option>
          ))}
        </Form.Select>
      </div>

      <div className="d-flex justify-content-between dashboard_item">
        <h3>Image Presets</h3>
        <Button
          onClick={() => setModalShow(true)}
          variant="primary"
          className="primary_bg"
        >
          Add Preset
        </Button>
      </div>
      <Table className="scanJob_table" bordered size="lg">
        <thead>
          <tr>
            <th>Preset ID</th>
            <th>Client ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Thumbnail</th>
            <th>Scene Template</th>
            <th>Resolutions</th>
            <th>Output Filetypes</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {imagePresets &&
            imagePresets.map((preset) => {
              const d = new Date(preset.createdAt);
              const p = new Date(preset.updatedAt);
              return (
                <tr key={preset._id}>
                  <td className="word_wrap">{preset._id}</td>
                  <td className="word_wrap">
                    {companyObj[preset.clientCompanyId]}
                  </td>
                  <td>{preset?.name}</td>
                  <td className="word_wrap">{preset?.description}</td>
                  <td className="word_wrap">
                    {preset?.thumbnail ? (
                      <img width={30} src={preset?.thumbnailLiveUrl} />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="word_wrap">
                    {preset.sceneTemplate && (
                      <a
                        href={preset?.sceneTemplateLiveUrl}
                        download={preset?.sceneTemplateLiveUrl}
                        title="Download"
                        className="scanjob_btn dual_btn"
                        size="sm"
                        variant="secondary"
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </a>
                    )}
                  </td>
                  <td>
                    {preset?.resolutionOptions.length > 0 &&
                      preset?.resolutionOptions.map((p, index) => {
                        const widthHeight = p?.width + "x" + p?.height;
                        return (
                          <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${p.name}`}>
                                {widthHeight}
                              </Tooltip>
                            }
                          >
                            <Button className="tooltip_btn">
                              {index === preset?.resolutionOptions?.length - 1
                                ? `${p?.name}`
                                : `${p?.name}, `}
                              &nbsp;
                            </Button>
                          </OverlayTrigger>
                        );
                      })}
                  </td>
                  <td>
                    {preset?.outputFileTypeOptions.length > 0 &&
                      preset?.outputFileTypeOptions.map((o, index) =>
                        index == preset?.outputFileTypeOptions?.length - 1
                          ? `${o}`
                          : `${o}, `
                      )}
                  </td>
                  <td>
                    {d.getDate()}.{d.getMonth() + 1}.{d.getFullYear()}{" "}
                    {d.getHours()}:{d.getMinutes()}
                  </td>
                  <td>
                    {p.getDate()}.{p.getMonth() + 1}.{p.getFullYear()}{" "}
                    {p.getHours()}:{p.getMinutes()}
                  </td>
                  <td>
                    <span
                      role="button"
                      onClick={() => handleEditPreset(preset)}
                    >
                      Edit
                    </span>
                    &nbsp;&nbsp;
                    <span
                      role="button"
                      onClick={() => handleDelet(preset._id, preset.name)}
                      className="text-danger"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <AddImagePresetsModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <EditPresetModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShowEditPreset}
        setModalShow={setModalShowEditPreset}
      />
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />

      <div className="pagination_product">
        <Form.Select
          disabled={imagePresets?.length < 5}
          onChange={handleLimit}
          className="limit_select-lg"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="35">35</option>
          <option value="50">50</option>
        </Form.Select>
        <Pagination size="lg" onClick={paginationNumber}>
          {items}
        </Pagination>
      </div>
    </div>
  );
};

export default ImagePresets;
