import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { companyEdit } from "../../features/editSlice";
import PageTitle from "../../hooks/PageTitle";
import AddCompanyModal from "../modal/AddCompanyModal";
import DeleteModal from "../modal/DeleteModal";
import EditCompanyModal from "../modal/EditCompanyModal";

const AddCompany = () => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [companyList, setCompanyList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(5);
  const dispatch = useDispatch();

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(`/companyroute?page=${page}&limit=${limit}`, {
        withCredentials: true,
      })
      .then((res) => {
        setCompanyList(res.data.company);
        setPageCounte(res.data.pageCount);
      });
  }, [refetch, page, limit]);

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };

  const deleteConfirm = () => {
    axios
      .delete(`/companyroute/delete/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.msg) {
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const handleEdit = (company) => {
    setModalShowEdit(true);
    dispatch(companyEdit(company));
  };

  let active = page;
  let items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const paginationNumber = (e) => {
    const pageNumber = parseInt(e.target.innerText);
    setPage(pageNumber);
  };

  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  return (
    <div className="login_form dashboard_page">
      <PageTitle title="Company" />

      <div className="d-flex justify-content-between dashboard_item">
        <h3>Companies</h3>
        <Button
          onClick={() => setModalShow(true)}
          variant="primary"
          className="primary_bg"
        >
          Add Company
        </Button>
      </div>
      <Table bordered size="lg">
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {companyList &&
            companyList?.map((company, index) => {
              return (
                <tr key={company._id}>
                  <td>{company._id}</td>
                  <td>{company.companyname}</td>
                  <td>{company.billingemail}</td>
                  <td>
                    <span role="button" onClick={() => handleEdit(company)}>
                      Edit
                    </span>
                    &nbsp;&nbsp;
                    <span
                      role="button"
                      onClick={() =>
                        handleDelet(company._id, company.companyname)
                      }
                      className="text-danger"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {/* MOdals */}
      <AddCompanyModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <EditCompanyModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShowEdit}
        setModalShow={setModalShowEdit}
      />
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />
      <div className="pagination_product">
        <Form.Select
          disabled={companyList?.length < 5}
          onChange={handleLimit}
          className="limit_select-lg"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="35">35</option>
          <option value="50">50</option>
        </Form.Select>
        <Pagination size="lg" onClick={paginationNumber}>
          {items}
        </Pagination>
      </div>
    </div>
  );
};

export default AddCompany;
