import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const AddUserModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompanyname] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [profilePicture, setProfilePicture] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);
  // console.log(profilePicture)

  const errorToast = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const HandleAddUser = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("company", company);
    formData.append("notifyApproval", notify);
    formData.append("profilePicture", profilePicture);

    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true,
    };
    if (company.length < 16) {
      errorToast("Select a company");
      return;
    }
    if (!role || role === "404") {
      errorToast("Please Select a Role");
      return;
    }
    try {
      await axios.post(`/authroute/signup`, formData, config).then((res) => {
        if (res.data.success) {
          toast.success(`user "${res.data.user.username}" added successfully`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setEmail("");
          setUsername("");
          setPassword("");
          setRole("");
          setCompanyname("");
          setProfilePicture("");
          setModalShow();
          setRefetch(!refetch);
          setIsLoading(false);
        }
        if (res.data.code === 11000) {
          errorToast("User name or email already exists");
          setIsLoading(false);
        }
      });
    } catch (error) {
      if (error.message) {
        errorToast(error.message);
        setIsLoading(false);
      }
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleAddUser}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="mb-3">Add new user</Form.Label>
            <Form.Control
              required
              type="email"
              className="email_filed"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="text"
              value={username}
              placeholder="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Select
            className="mb-3"
            value={company}
            onChange={(e) => setCompanyname(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select Company</option>
            {companyList.map((company) => (
              <option key={company._id} value={company._id}>
                {company.companyname}
              </option>
            ))}
          </Form.Select>

          <Form.Select
            value={role}
            className="mb-3"
            onChange={(e) => setRole(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select User Role</option>
            <option value="Client">Client</option>
            <option value="Producer">Producer</option>
            <option value="Administrator">Administrator</option>
          </Form.Select>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <span className="notofy_label">
              <span>Notify </span>
              <InputGroup.Checkbox
                onChange={() => setNotify(!notify)}
                aria-label="Checkbox for following text input"
              />
            </span>
            <Form.Label className="label_notify_email">
              (Send Email to user if Renderjob approval is required.)
            </Form.Label>
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="label_profile_picture">
              Choose Profile Picture (optional)
            </Form.Label>
            <Form.Control
              name="profile_picture"
              onChange={(e) => setProfilePicture(e.target.files[0])}
              type="file"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add User
          </Button>
        </Form>
      </Modal.Body>
      {isLoading && (
        <div className="submit_area">
          <>
            <Spinner size="lg" animation="border" />
            &nbsp; <span>Adding user...</span>
          </>
        </div>
      )}
    </Modal>
  );
};

export default AddUserModal;
