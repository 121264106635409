import React from 'react';
import { Nav } from 'react-bootstrap';
import CustomLink from '../../hooks/CustomLink';
import PageTitle from '../../hooks/PageTitle';
import './NotFound.css'

const NotFound = () => {
  return (
    <section className="mt-5">
      <PageTitle title="404, Not found" />
      <h1 className="title_nf">:-(</h1>

      <div className="row text-center mt-5">
        <div>
          <p>The page you are looking for does not exist.</p>
        </div>
      </div>

      <div className="text-center mt-2">
        <Nav.Link className="btn btn-outline-dark back_to_btn" as={CustomLink} to="/">Back to the start</Nav.Link>
      </div>
    </section>
  );
};

export default NotFound;