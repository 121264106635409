import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../hooks/PageTitle";
import RenderJobSubmitModal from "../modal/RenderJobSubmitModal";
import "./renderJob.css";

const Renderjob = () => {
  const [modalShow, setModalShow] = useState(false);
  const [presetsModal, setPresetsModal] = useState(false);
  const [renderJobs, setRenderJob] = useState([]);
  const [renderJobsFiles, setRenderJobFiles] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const fileInputRef = useRef();
  const goHome = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [cameraSection, setCameraSection] = useState(false);

  // field default value
  const [fieldOfView, setFieldOfView] = useState(30);
  const [camPosX, setCamPosX] = useState(0);
  const [camPosY, setCamPosY] = useState(1.5);
  const [camPosZ, setCamPosZ] = useState(4);
  const [targetPosX, setTargetPosX] = useState(0);
  const [targetPosY, setTargetPosY] = useState(0);
  const [targetPosZ, setTargetPosZ] = useState(0);
  const [resolutionWidth, setResolutionWidth] = useState(1920);
  const [resolutionHeight, setResolutionHeight] = useState(1080);
  const [selectCompany, setSelectCompany] = useState("");
  const [presetList, setPresetList] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState({});
  const [presetResolution, setPresetResolution] = useState("");
  const [presetOutputFiletype, setPresetOutputFiletype] = useState("");
  const [imagePreset, setImagePreset] = useState([]);
  const [selectResObj, setSelectResObj] = useState({});

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  const toastWarn = (mes) => {
    toast.warn(mes, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const enableNextForm = () => {
    if (selectCompany) {
      axios
        .get(`/imagePresetsRoute/company/${selectCompany}`, {
          withCredentials: true,
        })
        .then((res) => setPresetList(res.data.imagePresets));
      setShowForm(true);
    } else {
      toastWarn("Please select a company");
    }
  };

  const validateOCFile = (e) => {
    const { name, size } = e.target.files[0];
    const fileSize = Math.ceil(size / Math.pow(1024, 2));
    // const nameToArray = name.split(".");
    if (!name.endsWith(".oc.zip") && !name.endsWith(".glb")) {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      toastWarn(`Only allowed .oc.zip or .glb file`);
      return;
    } else if (fileSize > 300) {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      toastWarn(`Max file size allowed 300 MB`);
      return;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = value;
    setFieldOfView(value);
  };

  const validateNumberField = (e, limit, state, maxLimit = 0) => {
    const value = e.target.value.replace(/[^0-9]/g, "");

    if (value >= limit) {
      if (maxLimit > 0) {
        if (maxLimit < value) {
          toastWarn(`Not allowed more than ${maxLimit}`);
          e.target.value = maxLimit;
          state(maxLimit);
        } else {
          state(value);
        }
      } else {
        state(value);
      }
    } else {
      toastWarn(`Not allowed less than ${limit}`);
      e.target.value = limit;
      state(limit);
    }
  };

  const handleSelectPreset = (e) => {
    const presetId = e.target.value;
    setPresetResolution("");
    setPresetOutputFiletype("");
    if (presetId) {
      const findPreset = presetList.find((f) => f._id == presetId);
      setSelectedPreset(findPreset);
    }
  };

  const handleAddedRenderJob = (e) => {
    e.preventDefault();
    const name = e.target.name.value
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "_");
    const ocFile = e.target.ocFile.files[0].name;
    const md5 = e.target.md5.value;
    let duplicateValue = false;
    renderJobs.forEach((renderJob) => {
      if (renderJob.name == name) {
        toastWarn(`Duplicate RenderJob Name`);
        duplicateValue = true;
        return;
      }
      if (renderJob.ocFile == ocFile) {
        toastWarn(`Duplicate ocFile Name`);
        duplicateValue = true;
        return;
      }
    });
    if (duplicateValue) return;
    if (name && ocFile) {
      const renderJob = { name, ocFile, md5 };
      setRenderJob([...renderJobs, renderJob]);
      setRenderJobFiles([...renderJobsFiles, e.target.ocFile.files[0]]);
      e.target.reset();
      setModalShow(false);
    } else {
      toastWarn(`Name and 3D File fields are required`);
    }
  };

  const handleSelectRes = (e) => {
    const resId = e.target.value;
    setPresetResolution(resId);
    if (resId) {
      const findres = selectedPreset.resolutionOptions.find(
        (r) => r._id == resId
      );
      setSelectResObj(findres);
    }
  };
  const handleAddedPreset = (e) => {
    e.preventDefault();
    const preset = {
      name: selectedPreset.name,
      presetId: selectedPreset._id,
      sceneTemplate: selectedPreset.sceneTemplate,
      resolution: selectResObj,
      outputFileType: presetOutputFiletype,
    };
    setImagePreset([...imagePreset, preset]);
    setPresetsModal(false);
    setSelectResObj({});
    setPresetResolution("");
    setPresetOutputFiletype("");
  };

  const removeJob = (job) => {
    const filterJob = renderJobs.filter((j) => j.ocFile !== job);
    if (filterJob) {
      setRenderJob(filterJob);
    } else {
      setRenderJob([]);
    }

    const filterJobFiles = renderJobsFiles.filter((j) => j.name !== job);
    if (filterJobFiles) {
      setRenderJobFiles(filterJobFiles);
    } else {
      setRenderJobFiles([]);
    }
  };

  const removeImagePresets = (preset) => {
    const filterPreset = imagePreset.filter((j) => j.presetId !== preset);
    if (filterPreset) {
      setImagePreset(filterPreset);
    } else {
      setImagePreset([]);
    }
  };

  const submitRenderJob = async (e) => {
    e.preventDefault();
    if (renderJobs.length > 0) {
      if (!cameraSection && imagePreset.length == 0) {
        toastWarn(`Enable camera or add an Image Preset`);
        return;
      }
      setSubmitModal(true);
      const field = e.target;

      const posX = !cameraSection ? "" : parseFloat(field.posX.value);
      const posY = !cameraSection ? "" : parseFloat(field.posY.value);
      const posZ = !cameraSection ? "" : parseFloat(field.posZ.value);
      const type = !cameraSection ? "" : field.type.value;
      const clientCompanyId = field.company.value;
      const fov = !cameraSection ? "" : parseFloat(field.fov.value);
      const targetX = !cameraSection ? "" : parseFloat(field.targetX.value);
      const targetY = !cameraSection ? "" : parseFloat(field.targetY.value);
      const targetZ = !cameraSection ? "" : parseFloat(field.targetZ.value);
      const resolutionWidth = !cameraSection ? "" : field.resolutionWidth.value;
      const resolutionHeight = !cameraSection
        ? ""
        : field.resolutionHeight.value;
      const outputFileType = !cameraSection ? "" : field.outputFileType.value;
      const outputColorProfile = !cameraSection
        ? ""
        : field.outputColorProfile.value;
      const callbackUrl = field.callbackUrl.value;
      const clientEmail = field.clientEmail.value;
      const clientName = field.clientName.value;
      const clientComment = field.clientComment.value;

      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const urlRegex =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s]*)?$/;

      if (clientEmail) {
        const result = emailRegex.test(clientEmail);
        if (!result) {
          toastWarn(`Please provide a valid Recipient E-mail address`);
          setSubmitModal(false);
          return;
        }
      }

      if (callbackUrl) {
        const result = urlRegex.test(callbackUrl);
        if (!result) {
          toastWarn(`Please provide a valid Callback URL address`);
          setSubmitModal(false);
          return;
        }
      }

      const renderJobJson = {
        renderObjects: renderJobs,
        camera: {
          posX,
          posY,
          posZ,
          type,
          fov,
          targetX,
          targetY,
          targetZ,
        },
        outputFileType,
        outputColorProfile,
        callbackUrl,
        clientEmail,
        clientName,
        clientComment,
      };
      const camera = {
        posX,
        posY,
        posZ,
        type,
        fov,
        targetX,
        targetY,
        targetZ,
      };
      let formData = new FormData();
      cameraSection && formData.append("camera", JSON.stringify(camera));
      cameraSection && formData.append("resolutionWidth", resolutionWidth);
      cameraSection && formData.append("resolutionHeight", resolutionHeight);
      cameraSection && formData.append("outputFileType", outputFileType);
      cameraSection &&
        formData.append("outputColorProfile", outputColorProfile);
      formData.append("callbackUrl", callbackUrl);
      formData.append("clientEmail", clientEmail);
      formData.append("clientName", clientName);
      formData.append("clientCompanyId", clientCompanyId);
      formData.append("clientComment", clientComment);
      imagePreset &&
        formData.append("imagePresets", JSON.stringify(imagePreset));
      formData.append("renderObjects", JSON.stringify(renderJobs));
      renderJobsFiles.forEach((job) => {
        formData.append(`${job.name}`, job);
      });
      const config = {
        headers: { "content-type": "multipart/form-data" },
        withCredentials: true,
      };
      await axios.post(`/renderjobRoute/new`, formData, config).then((res) => {
        if (res.status == 200) {
          setTimeout(() => {
            setSubmitModal(false);
            toast.success(`RenderJob submitted successfully`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            goHome("/");
          }, 150);
        }
      });
    } else {
      toastWarn(`Add at least one render job`);
    }
  };

  return (
    <div>
      <PageTitle title="RenderJob - new" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <br />
            <br />
            <div className="d-flex justify-content-between dashboard_item">
              <h3>RenderJob</h3>
            </div>
            <div className="randerjob_new_area">
              <Form onSubmit={submitRenderJob}>
                <Row>
                  <h6 className="group_title fw-bold mb-4">Client</h6>
                  <Col>
                    <Form.Group className="mb-3" controlId="exampleForm.type">
                      <Form.Label>Company</Form.Label>
                      <Form.Select
                        name="company"
                        aria-label="Default select example"
                        disabled={showForm}
                        onChange={(e) => setSelectCompany(e.target.value)}
                      >
                        <option value="">Select company</option>
                        {companyList &&
                          companyList.map((company) => (
                            <option key={company._id} value={company._id}>
                              {company.companyname}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {showForm && (
                  <>
                    <h6 className="group_title fw-bold mb-4 mt-3">Model</h6>
                    <Button
                      onClick={() => setModalShow(true)}
                      className="mb-3 px-3"
                      variant="primary"
                      size="sm"
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                    {renderJobs && (
                      <Accordion className="mb-2">
                        <Row>
                          {renderJobs &&
                            renderJobs.map((r, index) => {
                              return (
                                <Col key={index}>
                                  <Accordion.Item
                                    className="single_acc_item"
                                    eventKey={index}
                                  >
                                    <Accordion.Header>
                                      {r?.name}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <b>ocFile</b>
                                      <br />
                                      {r?.ocFile} <br />
                                      <b>md5</b>
                                      <br />
                                      {r?.md5} <br />
                                      <Button
                                        onClick={() => removeJob(r?.ocFile)}
                                        className="mt-3"
                                        variant="danger"
                                        size="sm"
                                      >
                                        Remove
                                      </Button>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Col>
                              );
                            })}
                        </Row>
                      </Accordion>
                    )}
                    <Row className="camera_switch">
                      <h6 className="group_title fw-bold mb-4 camera_title">
                        Camera{" "}
                      </h6>
                      &nbsp;
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={cameraSection}
                        onChange={() => setCameraSection(!cameraSection)}
                      />
                    </Row>
                    {cameraSection && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.type"
                            >
                              <Form.Label>Type</Form.Label>
                              <Form.Select
                                name="type"
                                aria-label="Default select example"
                              >
                                <option value="perspective">perspective</option>
                                <option value="orthographic">
                                  orthographic
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.fov"
                            >
                              <Form.Label>Field of View*</Form.Label>
                              <Form.Control
                                name="fov"
                                type="number"
                                required
                                placeholder=""
                                defaultValue={fieldOfView}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.posX"
                            >
                              <Form.Label>Cam Pos X*</Form.Label>
                              <Form.Control
                                name="posX"
                                type="number"
                                required
                                placeholder=""
                                step="0.001"
                                defaultValue={camPosX}
                                onChange={(e) => setCamPosX(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.posY"
                            >
                              <Form.Label>Cam Pos Y*</Form.Label>
                              <Form.Control
                                name="posY"
                                type="number"
                                placeholder=""
                                required
                                step="0.001"
                                defaultValue={camPosY}
                                onChange={(e) => setCamPosY(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.posZ"
                            >
                              <Form.Label>Cam Pos Z*</Form.Label>
                              <Form.Control
                                name="posZ"
                                type="number"
                                placeholder=""
                                required
                                step="0.001"
                                defaultValue={camPosZ}
                                onChange={(e) => setCamPosZ(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.targetX"
                            >
                              <Form.Label>Target Pos X*</Form.Label>
                              <Form.Control
                                name="targetX"
                                type="number"
                                placeholder=""
                                required
                                step="0.001"
                                defaultValue={targetPosX}
                                onChange={(e) => setTargetPosX(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.targetY"
                            >
                              <Form.Label>Target Pos Y*</Form.Label>
                              <Form.Control
                                name="targetY"
                                type="number"
                                placeholder=""
                                required
                                step="0.001"
                                defaultValue={targetPosY}
                                onChange={(e) => setTargetPosY(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.targetZ"
                            >
                              <Form.Label>Target Pos Z*</Form.Label>
                              <Form.Control
                                name="targetZ"
                                type="number"
                                placeholder=""
                                required
                                step="0.001"
                                defaultValue={targetPosZ}
                                onChange={(e) => setTargetPosZ(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <h6 className="group_title fw-bold mb-4 mt-3">
                            Output
                          </h6>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.outputFileType"
                            >
                              <Form.Label>File Type</Form.Label>
                              <Form.Select
                                name="outputFileType"
                                aria-label="Default select example"
                              >
                                <option value="JPEG">JPEG</option>
                                <option value="PNG">PNG</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.outputColorProfile"
                            >
                              <Form.Label>Color Profile</Form.Label>
                              <Form.Select
                                name="outputColorProfile"
                                aria-label="Default select example"
                              >
                                <option value="RGB">RGB</option>
                                <option value="BW">BW</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.resolutionWidth"
                            >
                              <Form.Label>Resolution Width</Form.Label>
                              <Form.Control
                                name="resolutionWidth"
                                type="number"
                                placeholder=""
                                defaultValue={resolutionWidth}
                                onBlur={(e) =>
                                  validateNumberField(
                                    e,
                                    2,
                                    setResolutionWidth,
                                    3840
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.resolutionHeight"
                            >
                              <Form.Label>Resolution Height</Form.Label>
                              <Form.Control
                                name="resolutionHeight"
                                type="number"
                                placeholder=""
                                defaultValue={resolutionHeight}
                                onBlur={(e) =>
                                  validateNumberField(
                                    e,
                                    2,
                                    setResolutionHeight,
                                    2160
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}

                    <h6 className="group_title fw-bold mb-4 mt-3">
                      Image Presets
                    </h6>
                    <Button
                      onClick={() => setPresetsModal(true)}
                      className="mb-3 px-3"
                      variant="primary"
                      size="sm"
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                    {imagePreset && (
                      <Accordion className="mb-2">
                        <div className="accroding_grid_preset">
                          {imagePreset &&
                            imagePreset.map((r, index) => {
                              return (
                                <Col key={r.presetId}>
                                  <Accordion.Item
                                    className="single_acc_item presets_acc"
                                    eventKey={index}
                                  >
                                    <Accordion.Header>
                                      {r?.name}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <b>presetId</b>: {r?.presetId}, &nbsp;
                                      <b>OutputFiletype</b>: {r?.outputFileType}
                                      <br />
                                      <b>sceneTemplate</b>: {r?.sceneTemplate}
                                      <br />
                                      <b>Resolution</b>:<br />
                                      name: {r?.resolution?.name}&nbsp; width:{" "}
                                      {r?.resolution?.width}&nbsp; height:{" "}
                                      {r?.resolution?.height} <br />
                                      <Button
                                        onClick={() =>
                                          removeImagePresets(r?.presetId)
                                        }
                                        className="mt-3"
                                        variant="danger"
                                        size="sm"
                                      >
                                        Remove
                                      </Button>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Col>
                              );
                            })}
                        </div>
                      </Accordion>
                    )}

                    <Row>
                      <h6 className="group_title fw-bold mb-4 mt-3">
                        Delivery
                      </h6>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.clientName"
                        >
                          <Form.Label>Recipient Name</Form.Label>
                          <Form.Control
                            name="clientName"
                            type="text"
                            placeholder="Max Mustermann"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.clientComment"
                        >
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            name="clientComment"
                            type="text"
                            placeholder="Sending with love!"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.clientEmail"
                        >
                          <Form.Label>Recipient E-mail</Form.Label>
                          <Form.Control
                            name="clientEmail"
                            type="text"
                            placeholder="max.mustermann@mail.com"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.callbackUrl"
                        >
                          <Form.Label>Callback URL</Form.Label>
                          <Form.Control
                            name="callbackUrl"
                            type="text"
                            placeholder="https://my.callback.url"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <div className="buttonArea">
                  {!showForm && (
                    <span
                      className="cancleBtn nextButton"
                      onClick={enableNextForm}
                    >
                      Next
                    </span>
                  )}
                  {showForm && (
                    <Button
                      type="submit"
                      className="mt-3 renderJobButton"
                      variant="primary"
                      size="md"
                    >
                      Submit RenderJob
                    </Button>
                  )}
                  <span className="cancleBtn" onClick={() => goHome("/")}>
                    Cancel
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/*Model Modal */}
      <Modal
        backdrop="static"
        onHide={() => setModalShow(false)}
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Model
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddedRenderJob}>
            <Form.Group className="mb-3" controlId="exampleForm.ocFile">
              <Form.Label>3D File*</Form.Label>
              <Form.Control
                required
                name="ocFile"
                type="file"
                placeholder="Select Model"
                onChange={validateOCFile}
                accept=".zip, .glb"
                ref={fileInputRef}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.name">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                name="name"
                type="text"
                placeholder="My awesome model"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.md5">
              <Form.Label>Checksum (md5)</Form.Label>
              <Form.Control name="md5" type="text" placeholder="Optional" />
            </Form.Group>
            <Button type="submit" variant="primary" size="md">
              Add RenderJob
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/*Presets Modal */}
      <Modal
        backdrop="static"
        onHide={() => setPresetsModal(false)}
        show={presetsModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Preset
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddedPreset}>
            <Form.Group className="mb-3" controlId="exampleForm.outputFileType">
              <Form.Label>Preset*</Form.Label>
              <Form.Select
                name="presetId"
                required
                aria-label="Default select example"
                onChange={handleSelectPreset}
              >
                <option value="">Select Preset</option>
                {presetList &&
                  presetList.map((p) => (
                    <option key={p._id} value={p._id}>
                      {p.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.outputFileType">
              <Form.Label>Resolution*</Form.Label>
              <Form.Select
                name="resolutionOptions"
                required
                aria-label="Default select example"
                defaultValue={presetResolution}
                onChange={handleSelectRes}
              >
                <option value="">Select Resolution</option>
                {selectedPreset.resolutionOptions &&
                  selectedPreset.resolutionOptions.map((p) => (
                    <option key={p._id} value={p._id}>
                      {p.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ocFile">
              <Form.Label>Output Filetype*</Form.Label>
              <Form.Select
                name="outputFileType"
                required
                aria-label="Default select example"
                defaultValue={presetOutputFiletype}
                onChange={(e) => setPresetOutputFiletype(e.target.value)}
              >
                <option value="">Select Output Filetype</option>
                {selectedPreset.outputFileTypeOptions &&
                  selectedPreset.outputFileTypeOptions.map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Button type="submit" variant="primary" size="md">
              Add Preset
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {submitModal && (
        <RenderJobSubmitModal
          title="Submitting Renderjob"
          showModal={submitModal}
        />
      )}
    </div>
  );
};

export default Renderjob;
