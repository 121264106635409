import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NotFound from "./components/NotFound/NotFound";
import BodyTitle from "./components/bodyTitle/BodyTitle";
import AddCompany from "./components/dashboard/AddCompany";
import AddUser from "./components/dashboard/AddUser";
import ApiKey from "./components/dashboard/ApiKey";
import Dashboard from "./components/dashboard/Dashboard";
import ImagePresets from "./components/dashboard/ImagePresets";
import HomePage from "./components/homePage/HomePage";
import Login from "./components/login/Login";
import NavBar from "./components/navbar/NavBar";
import Renderjob from "./components/renderjob/Renderjob";
import CheckUserAdministrators from "./hooks/CheckUserAdministrators";
import RequireAuth from "./hooks/RequireAuth";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <NavBar />
      {location.pathname === "/faq" ||
      location.pathname.split("/")[1] === "renderjobs" ? (
        ""
      ) : (
        <BodyTitle />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        {/* <Route path="/renderjob/new" element={<Renderjob />} /> */}
        <Route
          path="/renderjob/new"
          element={
            <RequireAuth>
              <Renderjob />
            </RequireAuth>
          }
        />

        {/* <Route path="/administration" element={<Dashboard />}> */}
        <Route
          path="/administration"
          element={
            <RequireAuth>
              <CheckUserAdministrators>
                <Dashboard />
              </CheckUserAdministrators>
            </RequireAuth>
          }
        >
          <Route
            path="company-new"
            element={
              <RequireAuth>
                <AddCompany />
              </RequireAuth>
            }
          />

          <Route
            path="apikey-new"
            element={
              <RequireAuth>
                <ApiKey />
              </RequireAuth>
            }
          />

          {/* <Route path="imagePresets" element={<ImagePresets />} /> */}

          <Route
            path="imagePresets"
            element={
              <RequireAuth>
                <ImagePresets />
              </RequireAuth>
            }
          />

          <Route index element={<AddUser />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
